@font-face {
  font-family: "Helvetica Neue Custom";
  src: url("../fonts/HelveticaNeue.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue Custom";
  src: url("../fonts/HelveticaNeue-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue Custom";
  src: url("../fonts/HelveticaNeue-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue Custom";
  src: url("../fonts/HelveticaNeue-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
